import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  private hubConnection!: signalR.HubConnection;
  private baseUrl = 'https://dev.octopusmusicschool.com/signalr-octopus-chat'; 

  private messageSource = new BehaviorSubject<string>('');
  currentMessage = this.messageSource.asObservable();

  private newMessageCountSource = new BehaviorSubject<number>(0);
  newMessageCount = this.newMessageCountSource.asObservable(); 

  constructor() {}

  public startConnection(): void {
    if (this.hubConnection && this.hubConnection.state === signalR.HubConnectionState.Connected) {
      return;
    }

    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(this.baseUrl)
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information)
      .build();

    this.hubConnection
      .start()
      .then(() => console.log('SignalR Connected'))
      .catch((err) => console.error('Error connecting SignalR: ', err));

    this.listenForMessages();
  }

  private listenForMessages(): void {
    this.hubConnection.on('ReceiveMessage', (message: string) => {
      this.messageSource.next(message);
      this.updateMessageCount();
    });
  }

  public sendMessage(message: string): void {
    this.hubConnection.invoke('SendMessage', message).catch((err) => console.error(err));
  }

  public stopConnection(): void {
    if (this.hubConnection && this.hubConnection.state === signalR.HubConnectionState.Connected) {
      this.hubConnection.stop().then(() => console.log('SignalR Disconnected'));
    }
  }

  private updateMessageCount(): void {
    fetch('/api/messages/count') // Replace with your actual API
      .then((res) => res.json())
      .then((count) => this.newMessageCountSource.next(count))
      .catch((err) => console.error('Error fetching message count:', err));
  }
}