import { MbscPopupOptions } from '@mobiscroll/angular';
import { environment } from 'src/environments/environment';

export const Constants = {
  dockWindowEnabled: true,
  applicationLogoUrl: 'assets/images/logo.svg',
  applicationLogoDarkUrl: 'assets/images/dark-logo.png',
  environmentsForErrorTracing: ['localhost', environment.hostUrl],
  splashScreenTimeout: 200,
  dateFormat: 'M/d/y',
  fullDate: 'EE, MMM d, y',
  dateFormats: {
    yyyy_MM_dd_T_HH_mm_ss: 'yyyy-MM-ddTHH:mm:ss',
    yyyy_MM_dd: 'yyyy-MM-dd',
    EEE_MMM_dd: 'EEE, MMM dd',
    EEE_d: 'EEE d',
    day: 'E',
    fullDay: 'EEEE',
    yyyy_MM_DD: 'yyyy-MM-DD',
    hh_mm_a: 'hh:mm a',
    MMM_d: 'MMM d',
    HH_mm_ss: 'HH:mm:ss'
  },
  fiveMinutesGap: 5,
  defaultStudentCapacity: 8,
  minScheduleTime: '08:00:00',
  maxScheduleTime: '22:00:00',
  requiredAge: 18,
  totalLeaveBalance: 20,
  colors: {
    blueColor: '#1A73E8',
    whiteColor: '#FFFFFF',
    lightBlueColor: '#C1CEED',
    blackColor: '#222222',
    blackShadeColor: '#939393',
    primaryColor: '#42a694',
    headerBgColor: '#eef6ef'
  },
  filters: {
    whiteFilter: 'brightness(0) invert(1)',
    grayFilter:
      'brightness(0) saturate(100%) invert(51%) sepia(22%) saturate(289%) hue-rotate(194deg) brightness(97%) contrast(93%)',
    primaryFilter:
      'brightness(0) saturate(100%) invert(61%) sepia(15%) saturate(1371%) hue-rotate(119deg) brightness(90%) contrast(87%)'
  },
  disabledEventColors: {
    backgroundColor: '#cbcdd1',
    color: '#989a9f'
  },
  pattern: {
    PHONE_NUMBER_PATTERN: new RegExp(/^(?:(?:\+|0{0,2})91[\s-]?)?(?:(?:\(\d{1,3}\))|\d{1,3})[\s-]?\d{4}[\s-]?\d{3,4}$/),
    PASSWORD_PATTERN: new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/),
    EMAIL: new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/),
    NAME_PATTERN: new RegExp(/^[a-zA-Z ]+$/)
  },
  masking: {
    phoneNumberMask: '(000) 000-0000',
    dateMask: 'M0/d0/0000'
  },
  staticImages: {
    icons: {
      dashboard: 'assets/images/icons/dashboard-icon.svg',
      lockPassword: 'assets/images/icons/Lock-Password.svg',
      loginUser: 'assets/images/icons/Login-User.svg',
      addNew: 'assets/images/icons/Add-New.svg',
      google: 'assets/images/icons/google-logo.svg',
      microsoft: 'assets/images/icons/microsoft-logo.svg',
      thankYouMessage: 'assets/images/icons/thank-you-message-icon.svg',
      gear: 'assets/images/icons/gear.svg',
      location: 'assets/images/icons/location.svg',
      profileCircle: 'assets/images/icons/profile-circle.svg',
      timeCircleClock: 'assets/images/icons/time-circle-clock.svg',
      circleDollar: 'assets/images/icons/circle-dollar.svg',
      sidebarOpen: 'assets/images/icons/sidebar-open.svg',
      sidebarClose: 'assets/images/icons/sidebar-close.svg',
      visitScheduling: 'assets/images/icons/visit-scheduling-icon.svg',
      logOut: 'assets/images/icons/logout-icon.svg',
      editPen: 'assets/images/icons/edit-pen.svg',
      contentCopy: 'assets/images/icons/content-copy.svg',
      referral: 'assets/images/icons/referral.svg',
      gmail: 'assets/images/icons/gmail-logo.svg',
      help: 'assets/images/icons/help-icon.svg',
      gearWhite: 'assets/images/icons/gear-white.svg',
      trash: 'assets/images/icons/trash-icon.svg',
      editPenGray: 'assets/images/icons/edit-pen-gray.svg',
      checkCircle: 'assets/images/icons/check-circle.svg',
      fileUpload: 'assets/images/icons/file-upload.svg',
      documentIcon: 'assets/images/icons/document-icon.svg',
      roomIcon: 'assets/images/icons/room-icon.svg',
      instrumentIcon: 'assets/images/icons/instrument.svg',
      memberIcon: 'assets/images/icons/members-icon.svg',
      email: 'assets/images/icons/email-icon.svg',
      phone: 'assets/images/icons/phone-icon.svg',
      student: 'assets/images/icons/students-icon.svg',
      files: 'assets/images/icons/files-folders.svg',
      editPenGreen: 'assets/images/icons/edit-pen-green.svg',
      home: 'assets/images/icons/home-icon.svg',
      linkIcon: 'assets/images/icons/link-icon.svg',
      calendarIcon: 'assets/images/icons/calendar-icon.svg',
      musicWave: 'assets/images/icons/music-waves.svg',
      profileIcon: 'assets/images/icons/profile-icon.svg',
      scheduleIcon: 'assets/images/icons/schedule-icon.svg',
      introductoryLessonBorder: 'assets/images/icons/introductory-lesson-border.svg',
      multipleUserIcon: 'assets/images/icons/multiple-user-icon.svg',
      recurringLessonBorder: 'assets/images/icons/recurring-lesson-border.svg',
      filterIcon: 'assets/images/icons/filter-icon.svg',
      introductoryLessonOptionBorder: 'assets/images/icons/introductory-lesson-option-border.svg',
      expand: 'assets/images/icons/expand-btn.svg',
      redCross: 'assets/images/icons/red-cross.svg',
      skill: 'assets/images/icons/skill-ranking.svg',
      checkSquare: 'assets/images/icons/check-square.svg',
      people: 'assets/images/icons/people.svg',
      groupClassLessonBorder: 'assets/images/icons/group-class-lesson-border.svg',
      groupClassOptionLessonBorder: 'assets/images/icons/group-class-option-border.svg',
      questionnaire: 'assets/images/icons/questionnaire-tablet.svg',
      repeatType: 'assets/images/icons/repeat-type-icon.svg',
      safeHome: 'assets/images/icons/safe-home.svg',
      status: 'assets/images/icons/status.svg',
      fileCheck: 'assets/images/icons/file-check.svg',
      crossCircle: 'assets/images/icons/cross-circle.svg',
      summerCampOptionLessonBorder: 'assets/images/icons/summer-camp-option-border.svg',
      summerCampBorder: 'assets/images/icons/summer-camp-border.svg',
      checkedFile: 'assets/images/icons/checked-file.svg',
      arrowLeft: 'assets/images/icons/arrow-left.svg',
      shoppingBag: 'assets/images/icons/shopping-bag.svg',
      trophy: 'assets/images/icons/trophy-cup.svg',
      draftScheduleBorder: 'assets/images/icons/draft-schedule-border.svg',
      draftBadge: 'assets/images/icons/draft-badge.svg',
      clone: 'assets/images/icons/clone-copy.svg',
      redTrash: 'assets/images/icons/red-trash.svg',
      fileCheckBottom: 'assets/images/icons/file-check-bottom.svg',
      makeUpLessonBorder: 'assets/images/icons/make-up-lesson-border.svg',
      makeUpLessonOptionLessonBorder: 'assets/images/icons/make-up-lesson-option-border.svg',
      checkCircleWhite: 'assets/images/icons/check-circle-white.svg',
      noShowBadge: 'assets/images/icons/no-show-icon.svg',
      laptop: 'assets/images/icons/laptop-icon.svg',
      studentEnquiryIcon: 'assets/images/icons/student-enquiry-icon.svg',
      message: 'assets/images/icons/message-text.svg',
      whiteFilesFolders: 'assets/images/icons/files-folders-white.svg',
      redCrossCircle: 'assets/images/icons/red-cross-circle.svg',
      arrowsCircle: 'assets/images/icons/arrows-circle.svg',
      aeroplane: 'assets/images/icons/aeroplane.svg',
      whiteMessageIcon: 'assets/images/icons/message-white-icon.svg',
      sendIcon: 'assets/images/icons/send-icon.svg',
    },
    images: {
      profileImgPlaceholder: 'assets/images/profile-Image-placeholder.svg',
      pageNotFound: 'assets/images/page-not-found.svg',
      placeholderImage: 'assets/images/placeholder-image.jpg',
      pdfImage: 'assets/images/pdf-placeholder.svg',
      docImage: 'assets/images/doc-placeholder.svg'
    },
    loaders: {
      imageLoader: 'assets/loaders/image-loader.gif'
    }
  },
  successMessages: {
    savedSuccessfully: '{item} saved successfully',
    submittedSuccessfully: '{item} submitted successfully',
    updatedSuccessfully: '{item} updated successfully',
    addedSuccessfully: '{item} added successfully',
    deletedSuccessfully: '{item} deleted successfully',
    canceledSuccessfully: '{item} canceled successfully',
    removedSuccessfully: '{item} removed successfully',
    clonedSuccessfully: '{item} cloned successfully',
    markedSuccessfully: '{item} marked successfully',
    approvedSuccessfully: '{item} approved successfully',
    changePassword: 'Password updated successfully',
    imageCompression: 'Image compression is complete successfully',
    linkCopied: 'Link copied to clipboard',
    deleteUser: 'User Deleted successfully',
    theme: 'Theme updated successfully',
    forgotPasswordInitSuccess: 'Password reset link sent to your email. Please check your email.',
    passwordResetSuccess: 'Password reset successfully',
    emailSentSuccess: 'Email sent successfully',
    fileUploadSuccess: 'File Uploaded successfully',
    groupClassAssigned: 'Group assigned successfully',
    passwordSetSuccess: 'Password set successfully',
    paymentSuccess: 'Payment done successfully',
    signUpSuccess: 'User signed up successfully'
  },
  infoMessages: {
    imageCompression: 'No compression needed. Image size is within the limit.'
  },
  errorMessages: {
    email: 'Email is undefined',
    fileUploadPdfOnly: 'Only PDF files are allowed.',
    fileSizeExceeds: 'File size exceeds 2MB limit.',
    fileUploadLimt: 'Only one file can be uploaded at a time.',
    categoryNameRequired: 'Please add a category name to save category.',
    acceptTermsAndConditions: 'Please accept terms and conditions.',
    selectionPending: 'Please select {item} to continue.',
    maxDaysSelected: 'Maximum number of days selected for this plan.',
    invalidCardDetails: 'Please enter valid card details',
    invalidAttendance: 'Attendance cannot be marked for {item}.',
    userUnderAge: 'It looks like you are adding a child. Please ensure the account manager is added first.',
    duplicateInstruments: 'Duplicate instruments are not allowed.'
  },
  pageTitles: {
    quickLink: 'Quick Link'
  },
  refFromOptions: [
    { label: 'Social Media', value: 'SOCIAL_MEDIA' },
    { label: 'From a Friend', value: 'FROM_A_FRIEND' }
  ],
  skillGradeRanges: [
    { skillType: 'Beginner', minGrade: 1, maxGrade: 2 },
    { skillType: 'Intermediate', minGrade: 3, maxGrade: 6 },
    { skillType: 'Advanced', minGrade: 7, maxGrade: 10 }
  ],
  skillOptions: {
    BEGINNER: 'Beginner',
    INTERMEDIATE: 'Intermediate',
    ADVANCED: 'Advanced'
  },
  ageOptions: [
    { label: '2.5-5', value: 1 },
    { label: '6-8', value: 2 },
    { label: '9-17', value: 3 },
    { label: '18+', value: 4 }
  ],
  childAgeValues: {
    twoAndHalfToFive: 1,
    sixToEight: 2,
    nineToSeven: 3,
    eighteenPlus: 4
  },
  scheduleOptions: [
    {
      label: 'My Schedule',
      value: false
    },
    {
      label: 'Instructor',
      value: true
    }
  ],
  lessonTypeOptions: [
    {
      label: 'In-person Classes',
      value: false
    },
    {
      label: 'Duet™  Virtual Classes',
      value: true
    }
  ],
  lessonTypeValueOptions: [
    {
      label: 'In-person Classes',
      value: 1
    },
    {
      label: 'Duet™  Virtual Classes',
      value: 2
    }
  ],
  classTypeOptions: [
    {
      label: 'Introductory',
      value: 1
    },
    {
      label: 'Recurring',
      value: 2
    }
  ],
  allClassTypeOptions: [
    {
      label: 'Introductory',
      value: 1
    },
    {
      label: 'Recurring',
      value: 2
    },
    {
      label: 'Group Class',
      value: 3
    },
    {
      label: 'Summer Camp',
      value: 4
    },
    {
      label: 'Make-Up',
      value: 5
    }
  ],
  daysOfTheWeek: [
    {
      label: 'Mon',
      key: 'Monday',
      value: 1
    },
    {
      label: 'Tue',
      key: 'Tuesday',
      value: 2
    },
    {
      label: 'Wed',
      key: 'Wednesday',
      value: 3
    },
    {
      label: 'Thu',
      key: 'Thursday',
      value: 4
    },
    {
      label: 'Fri',
      key: 'Friday',
      value: 5
    },
    {
      label: 'Sat',
      key: 'Saturday',
      value: 6
    },
    {
      label: 'Sun',
      key: 'Sunday',
      value: 7
    }
  ],
  scheduleTimeSlots: {
    nineToElevenAM: 1,
    elevenToOnePM: 2,
    oneToThreePM: 3,
    threeToFivePM: 4,
    fiveToSevenPM: 5,
    sevenToNinePM: 6
  },

  scheduleTimeSlotsLabels: {
    1: '9:00 AM - 11:00 AM',
    2: '11:00 AM - 1:00 PM',
    3: '1:00 PM - 3:00 PM',
    4: '3:00 PM - 5:00 PM',
    5: '5:00 PM - 7:00 PM',
    6: '7:00 PM - 9:00 PM'
  },

  roles: {
    USER: 'User',
    ADMIN: 'Admin',
    ALL: 'All',
    DESK_MANAGER: 'DeskManager',
    INSTRUCTOR: 'Instructor',
    SUPERVISOR: 'Supervisor'
  },

  roleIds: {
    ADMIN: 2,
    CLIENT: 3,
    INSTRUCTOR: 4,
    DESK_MANAGER: 5,
    SUPERVISOR: 6
  },

  discountPercentage: {
    five: 5,
    ten: 10,
    fifteen: 15
  },

  passDuration: {
    thirty: 30,
    fourtyFive: 45,
    sixty: 60
  },

  schedulerViews: {
    List: 'List',
    Day: 'Day',
    Week: 'Week'
  },

  revenueCategories: {
    inPersonRevenue: 'Introductory Private Lessons',
    remoteRevenue: 'Introductory Remote Private Lessons',
    introductoryGroupClassRevenue: 'Introductory Group Classes'
  },

  editRecurringScheduleOptions: [
    {
      label: 'Change this instance only',
      value: false
    },
    {
      label: 'Change this and all future instances',
      value: true
    }
  ],

  defaultAmountPayableForIntroductoryClasses: 1
};

export const APP_CONFIG = {
  isTopNavEnabled: false,
  showFooter: false,
  showScrollToTop: true,
  horizontalProfileMenu: true
};

export const POPUP_OPTIONS: MbscPopupOptions = {
  display: 'anchored',
  touchUi: false,
  showOverlay: true,
  contentPadding: false,
  width: 450,
  maxHeight: 400,
  themeVariant: 'light',
  theme: 'ios',
  closeOnOverlayClick: true,
  scrollLock: false
};

export enum PROGRESS_TYPES {
  spinner = 'spinner',
  progressBar = 'progressBar'
}

export enum REFERRAL_TYPE {
  email = 1,
  copyLink = 2
}
