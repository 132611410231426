export const API_URL = {
  services: 'services',
  app: 'app',
  account: {
    root: 'Account',
    register: 'Register',
    login: 'Authenticate',
    loginWithThirdParty: 'ExternalAuthenticate',
    tokeAuth: 'TokenAuth',
    refreshAccessToken: 'RefreshToken',
    createUserDetails: 'CreateUserDetails',
    forgotPassword: {
      root: 'reset-password',
      init: 'SendPasswordResetCode',
      finish: 'ResetPassword'
    }
  },
  schoolLocations: {
    root: 'SchoolLocationses'
  },
  users: {
    root: '',
    paginatedList: 'paginated-sfl-users',
    action: 'sfl-users'
  },
  firebaseSubscription: {
    subscribe: 'subscribe',
    unsubscribe: ''
  },
  linkPreview: {
    apiURL: 'https://api.linkpreview.net',
    apiHeader: 'X-Linkpreview-Api-Key',
    apiHeaderKey: 'd3e4b7dddba1dfd87d2f3c7aa4f79fc6'
  },
  crud: {
    getAll: 'GetAll',
    create: 'Create',
    delete: 'Delete',
    createOrEdit: 'CreateOrEdit',
    update: 'Update',
    createOrUpdate: 'CreateOrUpdate'
  },
  instruments: {
    root: 'InstrumentDetails'
  },
  inquiry: {
    root: 'InquiryDetails'
  },
  subInstruments: {
    root: 'SubInstrumentDetails'
  },
  profile: {
    root: 'Profile',
    getCurrentUserProfileForEdit: 'GetCurrentUserProfileForEdit',
    changePassword: 'ChangePassword'
  },
  introductoryLesson: {
    root: 'IntroductoryLessons'
  },
  instructorDetails: {
    root: 'InstructorDetails',
    getInstructorDetailForEdit: 'GetInstructorDetailForEdit',
    getInstructorUnderSupervisor: 'GetInstructorUnderSupervisor'
  },
  uploadFile: {
    root: 'AWSFileUpload',
    uploadFileToS3: 'UploadFileToS3',
    deleteFileFromAws: 'DeleteFileFromAws',
    fileName: 'fileName'
  },
  helpCenters: {
    root: 'HelpCenters',
    edit: 'GetHelpCenterForEdit'
  },
  referralDetails: {
    root: 'ReferralDetails',
    createOrEdit: 'CreateOrEdit',
    create: 'Create'
  },
  dependentInformations: {
    root: 'DependentInformations',
    getDependentSchedule: 'GetDependentSchedule',
    assignStudentPlan: 'AssignStudentPlan',
    getDependentInformationForView: 'GetDependentInformationForView'
  },
  rewardSettings: {
    root: 'RewardSettings',
    getRewardSetting: 'GetRewardSetting'
  },
  documentDetails: {
    root: 'DocumentDetails'
  },
  roomDetails: {
    root: 'RoomDetails',
    getRoomDetailForView: 'GetRoomDetailForView'
  },
  roomInstruments: {
    root: 'RoomInstruments'
  },
  signedDocuments: {
    root: 'SignedDocuments'
  },
  roomSchedules: 'RoomSchedules',
  groupClassScheduleSummaries: {
    root: 'GroupClassScheduleSummaries',
    getGroupClassScheduleSummaryForView: 'GetGroupClassScheduleSummaryForView'
  },
  scheduleLessonDetails: {
    root: 'ScheduleLessonDetails',
    getScheduleLessonDetailForView: 'GetScheduleLessonDetailForView',
    cancelLesson: 'CancelLesson',
    getCurrentUserScheduleLessonDetail: 'GetCurrentUserScheduleLessonDetail',
    getInstructorAvaibility: 'GetInstructorAvaibility',
    getAllInstructor: 'GetAllInstructor',
    getAvailableIntroductoryLessons: 'GetAvailableIntroductoryLessons'
  },
  planSummaries: 'PlanSummaries',
  revenueCategories: {
    root: 'Categories',
    associateCategories: 'GetDataByCategoryId'
  },
  studentPlans: {
    root: 'StudentPlans',
    getStudentPlans: 'GetStudentPlans',
    getStudentPlansAndPasses: 'GetStudentPlansAndPasses'
  },
  passes: {
    root: 'Passes',
    updateStatus: 'UpdateStatus'
  },
  groupClassEnrollmentDetails: {
    root: 'GroupClassEnrollmentDetails',
    enrollStudent: 'EnrollStudent'
  },
  summerCampScheduleSummaries: {
    root: 'SummerCampScheduleSummaries',
    getSummerCampScheduleSummaryForView: 'GetSummerCampScheduleSummaryForView',
    getAvailableInstructors: 'GetAvailableInstructors'
  },
  summerCampEnrollmentDetails: {
    root: 'SummerCampEnrollmentDetails',
    enrollStudent: 'EnrollStudent'
  },
  studentGrades: {
    root: 'StudentGrades',
    getAllByStudentId: 'GetAllByStudentId'
  },
  payment: {
    root: 'Payment',
    cardConnectPayment: 'CardConnectPayment'
  },
  deskManagerDetails: {
    root: 'DeskManagerDetailses'
  },
  classAttendanceAppServices: 'ClassAttendanceAppServices',
  planCancelRequest: {
    root: 'PlanCancelRequest',
    cancelRequest: 'CancelRequest',
    approveRequest: 'ApproveRequest'
  },
  leaveManagement: {
    root: 'LeaveManagement',
    getLeaveRequest: 'GetLeaveRequest',
    leaveApproval: 'LeaveApproval',
    getLeaveBalance: 'GetLeaveBalance',
    getAllSubstituteAvailability: 'getAllSubstituteAvailability',
    createUpdate: 'CreateUpdate',
    getUserEmailDropDown: 'GetUserEmailDropDown',
    deleteLeaveRequest: 'DeleteLeaveRequest'
  }
};
